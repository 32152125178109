import * as whintegration from '@mod-system/js/wh/integration';

import "./lastwill.scss";

import * as dompack from "dompack";
import $ from "jquery";
//const { format } = require('number-currency-format');
// import URLBuilder from 'dompack/extra/urlbuilder';

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains("page-lastwill")) return;

  $('button[type="submit"] .wh-form__buttonlabel').text("Volgende");

  $("#mainpage").css("visibility", "visible");

  $('div[data-wh-form-pagerole="thankyou"]').append(`
    <div class="ff-lastwillfield__actionbuttons">
      <a target="_blank" id="downloadpdfbutton" href="${whintegration.config.obj.downloadpdfurl}" class="button">Download uw wilsbeschikking</a>

    </div>`);

  dompack
    .qS("#mainform > form")
    .addEventListener("wh:form-submitted", (evt) => {
      document.documentElement.classList.add("lastwillform-submitted");
      // add resultsguid to download PDF button
      let url = new URL($("#downloadpdfbutton").attr("href"));
      url.searchParams.set("resultguid", evt.detail.result.resultsguid);
      $("#downloadpdfbutton").attr("href", url.toString());

      $('div[data-wh-form-pagerole="thankyou"]').append(`
    <div class="ff-lastwillfield__actionbuttons">
      <a id="editform" href="?editform=${evt.detail.result.resultsguid}" class="button">Opnieuw invullen</a>

    </div>`);
    });
});
