import * as whintegration from '@mod-system/js/wh/integration';

import "./calculation.scss";

import * as dompack from "dompack";

import $ from "jquery";
// import URLBuilder from 'dompack/extra/urlbuilder';
import { SplitDateField } from "@mod-publisher/js/forms/fields/splitdatetime";
// Use split date fields for date fields that don't use flatpickr
//dompack.register('.js-no-date-picker', node => new SplitDateField(node));

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains("page-calculation")) return;

  $(window).keydown(function (event) {
    if (event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  });

  $("#mainpage").css("visibility", "visible");

  $('div[data-wh-form-pagerole="thankyou"]').append(`
    <div class="ff-calculationfield__actionbuttons">
      <a target="_blank" id="downloadpdfbutton" href="${whintegration.config.obj.downloadpdfurl}" class="button">Download PDF</a>

    </div>`);

  dompack.qS("form.wh-form").addEventListener("wh:form-submitted", (evt) => {
    document.documentElement.classList.add("calculationform-submitted");
    // add resultsguid to download PDF button
    let url = new URL($("#downloadpdfbutton").attr("href"));
    url.searchParams.set("resultguid", evt.detail.result.resultsguid);
    $("#downloadpdfbutton").attr("href", url.toString());
  });
});
