(function($) {

	skel
		.breakpoints({
			desktop: '(min-width: 737px)',
			tablet: '(min-width: 737px) and (max-width: 1200px)',
			mobile: '(max-width: 736px)'
		});

	$(function() {

		var	$window = $(window),
			$body = $('body');

			try {
				// Prioritize "important" elements on mobile.
				skel.on('+mobile -mobile', function() {
					$.prioritize(
						'.important\\28 mobile\\29',
						skel.breakpoint('mobile').active
						);
					});
			} catch(e) {}

		// Dropdowns.
			$('#page-header nav > ul').dropotron({
				offsetY: -13,
        //expandMode: 'click',
			});

		// Off-Canvas Navigation.

			// Title Bar.
				$(
					'<div id="titleBar">' +
						'<a href="#navPanel" class="toggle"></a>' +
						'<span class="title">' + $('#logolink')[0].outerHTML + '</span>' +
					'</div>'
				)
					.appendTo($body);

			// Navigation Panel.
				$(
					'<div id="navPanel">' +
						'<nav>' +
							$('#nav').navList() +
						'</nav>' +
					'</div>'
				)
					.appendTo($body)
					.panel({
						delay: 500,
						hideOnClick: true,
						hideOnSwipe: true,
						resetScroll: true,
						resetForms: true,
						side: 'left',
						target: $body,
						visibleClass: 'navPanel-visible'
					});

				//On click event listener to extend folded mobile navigation.
				$('#navPanel').on('click', 'span', function(e){
					if($(e.target).parent().find('ul').get(0).classList.contains("active")){
						$(e.target).parent().find('ul').get(0).style.display = "none";
			  		$(e.target).parent().find('ul').get(0).classList.remove("active");
			  		$(e.target).removeClass("fa-caret-up");
			  		$(e.target).addClass("fa-caret-down");
					}else{
			  		$(e.target).parent().find('ul').get(0).style.display = "block";
				  	$(e.target).parent().find('ul').get(0).classList.add("active");
				  	$(e.target).removeClass("fa-caret-down");
				  	$(e.target).addClass("fa-caret-up");
				  }
				});

		// Banner.
			var $banner = $('#banner');
			if ($banner.length > 0) {

				$banner.slidertron({
					mode: 'fade',
					viewerSelector: '.viewer',
					reelSelector: '.viewer .reel',
					slidesSelector: '.viewer .reel .slide',
					navNextSelector: '.nav-next',
					navPreviousSelector: '.nav-previous',
					slideCaptionSelector: '.caption-',
					captionLineSelector: '.caption-line-',
					captionLines: 2,
					advanceDelay: 7500,
					speed: 1000,
					autoFit: true,
					autoFitAspectRatio: (1200 / 440),
					seamlessWrap: false
				});

				$window
					.on('resize load', function() {
						$banner.trigger('slidertron_reFit');
					})
					.trigger('resize');

			}

	});

})(jQuery);
