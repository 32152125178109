import "./familymembers.scss";

import * as dompack from "dompack";
import { setupValidator } from "@mod-publisher/js/forms";
import JSONRPC from "@mod-system/js/net/jsonrpc";
import * as util from "../../webdesigns/twenthe/js/util";

let $ = window.$;

export default class FamilyMembersField {
  constructor(node, options) {
    this.$node = $(node);

    this.$node
      .closest(".wh-form__fieldgroup")
      .addClass("wh-form__fieldgroup--ff-familymembers");

    this.$rowsContainer = this.$node.find(".ff-familymembers__rows");

    node.addEventListener("wh:form-getvalue", (event) => this.getValue(event));

    this.$node
      .find(".ff-familymembers__newrow")
      .click(this.addRowEvent.bind(this));

    for (const familyMember of this.$node.data("familymembers")) {
      this.addRow(familyMember);
    }
  }

  addRowEvent(evt) {
    evt.preventDefault();
    this.addRow();
  }

  addRow(familyMember) {
    let numRows = this.$node.find(".ff-familymembers__row").length;
    let isFirst = numRows == 0;

    let costsValCents = 0;
    //if (isFirst) {
    let url = new URL(location.href);
    let costsVal = parseInt(url.searchParams.get("value")) || 500000;
    costsValCents = costsVal ? costsVal / 100 : 0;
    //}

    let $row = $(`<div class="ff-familymembers__row">
          <div class="wh-form__fieldgroup wh-form__fieldgroup--required">
            <a class="wh-anchor"></a>
            <label class="wh-form__label">Geboortedatum</label>
            <div class="wh-form__fields">
              <div class="wh-form__fieldline">
                <input class="wh-form__textinput dob" type="text" id="dob${numRows}" name="dob${numRows}" required>
              </div>
              <div class="wh-form__error"></div>
            </div>
          </div>
          <div class="wh-form__fieldgroup wh-form__fieldgroup--radiogroup wh-form__fieldgroup--required">
            <label class="wh-form__label">Gewenst verzekerd bedrag:</label>
            <div class="wh-form__fields">
              <div class="wh-form__fieldline wh-form__fieldline--nowrap">
                <input type="radio" class="wh-form__radio" value="3000" name="insuredvalue${numRows}" id="insuredvalue01${numRows}">
                <span class="wh-form__optiondata wh-form__optiondata--vertical">
                  <label for="insuredvalue01${numRows}" class="wh-form__optionlabel">€&nbsp;3000</label>
                </span>
              </div>
              <div class="wh-form__fieldline wh-form__fieldline--nowrap">
                <input type="radio" class="wh-form__radio" value="5000" name="insuredvalue${numRows}" id="insuredvalue02${numRows}" ${
      parseInt(url.searchParams.get("value")) ? "" : "checked"
    }>
                <span class="wh-form__optiondata wh-form__optiondata--vertical">
                  <label for="insuredvalue02${numRows}" class="wh-form__optionlabel">€&nbsp;5000 (meest gekozen)</label>
                </span>
              </div>
              <div class="wh-form__fieldline wh-form__fieldline--nowrap">
                <input type="radio" class="wh-form__radio" value="7000" name="insuredvalue${numRows}" id="insuredvalue03${numRows}">
                <span class="wh-form__optiondata wh-form__optiondata--vertical">
                  <label for="insuredvalue03${numRows}" class="wh-form__optionlabel">€&nbsp;7000</label>
                </span>
              </div>
              <div class="wh-form__fieldline wh-form__fieldline--nowrap">
                <input type="radio" class="wh-form__radio" value="other" name="insuredvalue${numRows}" id="insuredvalue04${numRows}" ${
      parseInt(url.searchParams.get("value")) ? "checked" : ""
    }>
                <span class="wh-form__optiondata wh-form__optiondata--vertical">
                  <label for="insuredvalue04${numRows}" class="wh-form__optionlabel">Ander bedrag</label>
                </span>
              </div>
            </div>
          </div>
          <div class="wh-form__fieldgroup wh-form__fieldgroup--textedit wh-form__fieldgroup--required">
            <label class="wh-form__label">Verzekerd bedrag</label>
            <div>
              <span class="wh-form__prefix">€</span>
              <input type="number" maxlength="7" max="1000000.0" id="insuredamountselected${numRows}" name="insuredamountselected${numRows}" class="wh-form__textinput insuredamountselected" required readonly step="0.01" min="0.0" value="${
      costsValCents ? costsValCents : ""
    }">

            </div>
          </div>
          <div class="wh-form__fieldgroup wh-form__fieldgroup--textedit wh-form__fieldgroup--required">
            <a class="wh-anchor"></a>
            <label class="wh-form__label">Gewenst verzekerd bedrag</label>
            <div class="wh-form__fields">
              <div class="wh-form__fieldline">
                <span class="wh-form__prefix">€</span>
                <input type="number" maxlength="7" max="1000000.0" name="insuredamount${numRows}" class="wh-form__textinput insuredamount" placeholder="Vul hier het gewenste bedrag in" required step="0.01" min="0.0" value="${
      costsValCents ? costsValCents : ""
    }">
              </div>
              <div class="wh-form__error"></div>
            </div>
          </div>
          <div class="wh-form__fieldgroup wh-form__fieldgroup--textedit">
            <label class="wh-form__label">Premie per maand</label>
            <div>
              <span class="wh-form__prefix">€</span>
              <input type="text" name="costspermonth${numRows}" class="wh-form__textinput costspermonth" required readonly>
            </div>
          </div>
          <div class="ff-familymembers__actions">
            ${
              !isFirst
                ? '<a href="#" class="ff-familymembers__removerow js-familymembers-remove fa fa-minus-circle title="Verwijder deze rij"></a>'
                : ""
            }
            <a class="ff-familymembers__addrow js-familymembers-add fa fa-plus-circle" title="Voeg nieuwe rij toe"></a>
          </div>
        </div>`);

    $row.find(".js-familymembers-add").click(this.addRowEvent.bind(this));
    $row.find(".js-familymembers-remove").click(this.removeRow.bind(this));

    let fmfield = this; // In order to access recalculateTotalCostsForRow function
    if (parseInt(url.searchParams.get("value")))
      $(
        $row
          .find(`#insuredamountselected${numRows}`)
          .closest(".wh-form__fieldgroup")
      ).css("display", "none");
    else
      $($row.find(`.insuredamount`).closest(".wh-form__fieldgroup")).css(
        "display",
        "none"
      );

    dompack.register("input.insuredamount", (node) =>
      setupValidator(node, () => {
        if (node.value == "") return "Dit veld is verplicht.";

        if (node.value > 15000)
          return "De waarde moet tussen de €0,00 en €15.000,00 liggen, voor andere opties, neem contact met ons op.";
      })
    );

    $row.find(`input[name='insuredvalue${numRows}']`).change(function () {
      if (this.value == "other") {
        // Display input field for the user to input a insuredamont themselves
        $($row.find(`.insuredamount`)).val("");
        $(
          $row
            .find(`#insuredamountselected${numRows}`)
            .closest(".wh-form__fieldgroup")
        ).css("display", "none");
        $($row.find(".insuredamount").closest(".wh-form__fieldgroup")).css(
          "display",
          "flex"
        );
        fmfield.recalculateTotalCostsForRow($row, isFirst);
      } else {
        // Display insured amount as text
        $(`#insuredamountselected${numRows}`).val(this.value);
        $row.find(".insuredamount").val(this.value);
        $(
          $row
            .find(`#insuredamountselected${numRows}`)
            .closest(".wh-form__fieldgroup")
        ).css("display", "flex");
        $($row.find(".insuredamount").closest(".wh-form__fieldgroup")).css(
          "display",
          "none"
        );
        fmfield.recalculateTotalCostsForRow($row, isFirst);
      }
    });

    // DOB validation
    let $dateObj = $row.find(`#dob${numRows}`);
    window.IMask($dateObj[0],
      {
        mask: "00-00-0000",
        min: new Date(1990, 0, 1),
        max: new Date(2020, 0, 1),
        lazy: false
      });

    dompack.register("input.dob", (node) =>
      setupValidator(node, () => {
        if (node.value == "") return "Dit veld is verplicht.";
        let date = new Date(node.value);
        if (
          isNaN(Date.parse(date)) ||
          new Date(date.toDateString()) < new Date(new Date("01-01-1900").toDateString()) ||
          new Date(date.toDateString()) > new Date(new Date().toDateString())
        ) {
          return "Dit is geen geldige datum.";
        }

        if ($row.find(".insuredamount").val() != "")
          this.recalculateTotalCostsForRow($row, isFirst);

        return "";
      })
    );

    this.$rowsContainer.append($row);
    dompack.registerMissed($row.get(0));

    for (const node of $row.get(0).querySelectorAll("input.insuredamount")) {
      $(node).on("input propertychange", (evt) => {
        this.recalculateTotalCostsForRow($(node), isFirst);
      });
    }

    if (isFirst) {
      if (dompack.debugflags["debug"]) {
        $row.find("input.dob").val("21-12-1981");
        // $('input.insuredamount').val('15000');
      }

      if (costsValCents) {
        this.recalculateTotalCostsForRow($row, true);
      }
    } else {
      // Fills in the insured value of the first familymember to the second familymember
      let insuredamount = $(
        $($(".ff-familymembers__row")[0]).find(".insuredamount")
      ).val();
      if (insuredamount !== undefined) {
        $($row.find(".insuredamount")).val(insuredamount);
        this.recalculateTotalCostsForRow($row);
      }
    }
  }

  removeRow(evt) {
    evt.preventDefault();

    swal
      .fire({
        title: "Rij verwijderen",
        text: "Weet u zeker dat u deze rij wilt verwijderen?",
        type: "question",
        confirmButtonText: "Ja",
        showCancelButton: true,
        cancelButtonText: "Nee",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          $(evt.target).closest(".ff-familymembers__row").remove();
        }

        this.recalculateTotalCostsMonthly();
      });
  }

  async recalculateTotalCostsForRow($row, isFirst = false) {
    $row = $row.closest(".ff-familymembers__row");

    let rpc = new JSONRPC();
    let $dob = $row.find(".dob");
    let dobRawVal = $dob.val();
    let dob = new Date(dobRawVal);

    if (isNaN(Date.parse(dob))) return;

    let insuredAmountRawVal = $row.find(".insuredamount").val();
    let insuredAmountVal = insuredAmountRawVal
      ? parseFloat(insuredAmountRawVal).toFixed(2)
      : 0;
    let insuredamountcents = parseInt((insuredAmountVal * 100).toFixed(0), 10);

    try {
      let calculation = await rpc.async(
        "Calculate",
        dob,
        insuredamountcents.toString(),
        isFirst
      );

      let $costs = $row.find("input.costspermonth");
      if (calculation.costs.not_possible) $costs.val("Niet mogelijk");
      else if (calculation.costs.is_co_insured) $costs.val("Gratis");
      else if (calculation.costs.total_costs_cents <= 0) $costs.val("");
      else
        $costs.val(
          util.currencyFormat(calculation.costs.total_costs_cents / 100)
        );

      this.recalculateTotalCostsMonthly();
    } catch (e) {
      $row.find("input.costspermonth").val("");
    }
  }

  // Calculates total monthly cost from all rows
  recalculateTotalCostsMonthly() {
    let $insuredAmountList = $("input.costspermonth");
    let total = 0.0;

    $insuredAmountList.each(function () {
      let insuredAmountRawVal = $(this).val().replace(",", ".");
      let insuredAmountVal = insuredAmountRawVal
        ? parseFloat(insuredAmountRawVal).toFixed(2)
        : parseFloat(0.0);
      let insuredamountcents = parseInt(
        (insuredAmountVal * 100).toFixed(0),
        10
      );

      if (
        insuredAmountVal.toString() !== "NaN" &&
        insuredAmountVal.toString() !== "Gratis" &&
        insuredAmountVal.toString() !== "Niet mogelijk"
      ) {
        if (total != 0.0) total = total + insuredamountcents;
        else total = insuredamountcents;
      }
    });

    total = parseFloat(total / 100).toFixed(2);
    if (total > 0)
      $(".costspermonthtotal").val(total.toString().replace(".", ","));
    else $(".costspermonthtotal").val("");
  }

  setValue(imgurl) {}

  getValue(event) {
    event.preventDefault();
    event.stopPropagation();

    let familymembers = [];

    $(".ff-familymembers__row").each(function (idx) {
      familymembers.push({
        dob: new Date($(this).find("input.dob").val()),
        insuredamount: $(this).find("input.insuredamount").val(),
        costspermonth: $(this).find("input.costspermonth").val(),
        costspermonthtotal: "",
      });
    });

    // Adds total monthly cost to the list
    familymembers.push({
      dob: new Date(),
      insuredamount: "",
      costspermonth: "",
      costspermonthtotal: $(".ff-familymembers")
        .find("input.costspermonthtotal")
        .val(),
    });

    event.detail.deferred.resolve(familymembers);
  }
}
