import * as dompack from "dompack";

import $ from "jquery";
import IMask from "imask";
window.IMaks = IMask;

import "sweetalert2/dist/sweetalert2.min.css";

import "@mod-mso_pixelarity/webdesigns/hub/hub";
import "./twenthe.scss";

import "./pages/calculation/calculation";
import "./pages/valuemeter/valuemeter";
import "./pages/lastwill/lastwill";


window.$ = $;

import ValueMeterField from "../../formcomponents/valuemeterfield/valuemeterfield";
dompack.register(".ff-valuemeterfield", (node) => new ValueMeterField(node));

import FamilyMembersField from "../../formcomponents/familymembers/familymembers";
dompack.register(".ff-familymembers", (node) => new FamilyMembersField(node));

dompack.onDomReady(() => {
	$.when( $.ready ).then(function() {
		// Document is ready.
		
		// expand sidebar nav by default
		let elements = $('#navPanel').find('.opener');
		console.log(elements);

		elements.each(function(){
			element = $(this);
			console.log(element.find('ul'));
			$(element).find('ul').get(0).style.display = "block";
			$(element).find('ul').get(0).classList.add("active");
			$(element).find('.fa').get(0).classList.remove("fa-caret-down");
			$(element).find('.fa').get(0).classList.add("fa-caret-up");
		});
	});
});
