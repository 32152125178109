import * as dompack from 'dompack';

// general JS (always add)
import '@mod-mijnsiteonline/nodejs/mso';

// main CSS
import 'font-awesome/css/font-awesome.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './web/css/stylereset.min.css';
import './web/css/rtd.css'; // cannot be SCSS
import './web/css/jquery-ui-1.10.3.custom.css';
import './web/css/timepicker.css';
import './web/css/default.css';
import './web/css/uniform.css';

import './hub.scss';

// pages
import './pages/agenda/agenda';
import './pages/intranet/intranet';
import './pages/news/news';
import './pages/organizations/organizations';
import './pages/search/search';
import './pages/team/team';

// components
import './sponsors/sponsors.es';
import './widgets/widgets.es';
import './slideshow/slideshow.es';

// template specific JS files
window.$ = require('jquery');
window.jQuery = $;
require('swiper');
require('./web/js/jquery.dropotron.min.js');
require('./web/js/jquery.slidertron.min.js');
window.skel = require('./web/js/skel.min.js');
require('./web/js/skel-viewport.min.js');
require('./web/js/util.js');
require('./web/js/main.js');

dompack.onDomReady(() => {
});

