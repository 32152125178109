import * as whintegration from '@mod-system/js/wh/integration';

import "./valuemeter.scss";

import * as dompack from "dompack";
import $ from "jquery";
// import URLBuilder from 'dompack/extra/urlbuilder';

import * as util from "../../js/util";

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains("page-valuemeter")) return;

  $(".ff-valuemeterfield--totalcosts").insertBefore($(".wh-form__navbuttons"));

  $('button[type="submit"] .wh-form__buttonlabel').text("Volgende");

  $("#mainpage").css("visibility", "visible");

  let $baseCostsInput = $(".js-base-costs");
  let baseCostsCents = parseInt($baseCostsInput.data("valcents")) || 0;
  $baseCostsInput.val(util.currencyFormat(baseCostsCents / 100));

  $("#mainpage select").change(onOptionChange);
  $(".js-selected-value-input--own").on("input", onOwnInputInput);
  recalculateTotal();

  $('div[data-wh-form-pagerole="thankyou"]').append(`
    <div class="ff-valuemeterfield__actionbuttons">
      <a target="_blank" id="downloadpdfbutton" href="${whintegration.config.obj.downloadpdfurl}" class="button">Download PDF</a>
      <a id="tocalcformbutton" href="${whintegration.config.obj.calcformurl}" class="button">Naar premieberekening</a>
    </div>`);

  dompack.qS("form.wh-form").addEventListener("wh:form-submitted", (evt) => {
    document.documentElement.classList.add("valuemeterform-submitted");

    // add total value to link to calculation form
    let valCents = $(".js-total-value-input").get(0).getAttribute("data-cents");
    if (valCents) {
      let url = new URL($("#tocalcformbutton").attr("href"));
      url.searchParams.set("value", valCents);
      $("#tocalcformbutton").attr("href", url.toString());
    }

    // add resultsguid to download PDF button
    let url = new URL($("#downloadpdfbutton").attr("href"));
    url.searchParams.set("resultguid", evt.detail.result.resultsguid);
    $("#downloadpdfbutton").attr("href", url.toString());
  });

  $(".ff-valuemeterfield__select").each(function () {
    // $(this).val($(this).find('option:nth-child(2)').val());
    // $(this).trigger('change');
  });
});

function onOptionChange(evt) {
  let $selectedOption = $(evt.target).find(":selected");
  let valCents = parseInt($selectedOption.data("value-cents")) || 0;
  if (valCents < 0) valCents = -1 * valCents;

  let $valueInput = $(evt.target)
    .closest(".ff-valuemeterfield")
    .find(".js-selected-value-input");

  $valueInput.val(valCents == 0 ? "" : util.currencyFormat(valCents / 100));
  $valueInput.get(0).setAttribute("data-valcents", valCents);

  recalculateTotal();
}

function onOwnInputInput(evt) {
  let val = parseInt($(this).val()) || 0;
  $(this)
    .get(0)
    .setAttribute("data-valcents", val * 100);
  recalculateTotal();
}

function recalculateTotal() {
  let totalCents = 0;
  $(".js-selected-value-input").each(function () {
    let valCents = parseInt($(this).get(0).getAttribute("data-valcents")) || 0;
    if ($(this).hasClass("js-selected-value-input--own"))
      totalCents -= valCents;
    else totalCents += valCents;
  });

  let $totalValue = $(".js-total-value-input");
  $totalValue.val(totalCents > 0 ? util.currencyFormat(totalCents / 100) : "0");
  $totalValue.get(0).setAttribute("data-cents", totalCents);
}
