const { format } = require('number-currency-format');

export function currencyFormat(val) {
  return format(val, { currency: '',
                       thousandSeparator: '',
                       decimalSeparator: ',',
                       showDecimals: 'IF_NEEDED',
                       currencyPosition: 'LEFT',
                       spacing: false,
                     });
}
